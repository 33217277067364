<template>
  <div class="NameWithCode">
    <div class="nameSection">
      <div class="label">
        {{ $t('name') }}
      </div>
      <div class="name">
        {{ name.value }}
      </div>
    </div>
    <div class="codeSection">
      <div class="label">
        {{ $t('code') }}
      </div>
      <div
        v-if="code"
        class="code">
        {{ code.code }}
      </div>
    </div>
  </div>
</template>
<translations>
  name: Name
  name_no: Navn
  code: Code
  code_no: Kode
</translations>
<script>
export default {
    inject: ['module'],
    props: {
        nameId: {
            type: String,
            required: true
        }
    },
    computed: {
        name() {
            return this.$store.getters['moduleSystemName/getNameById'](this.nameId);
        },
        code() {
            // return 'the code'
            let code = null;
            if (this.name.codes) {
                code = this.$store.getters['moduleSystemName/getCodeById'](this.name.codes[0]);
            }
            return code;
        }
    }
};
</script>

<style scoped lang="scss">
.NameWithCode {
    // border-radius: 0.5em;
    overflow: hidden;
    padding: 1.5em 2em;
    background-color: white;
    color: mix(black, white, 80%);
    display: flex;
    align-items: center;
}
.name {
    flex: 1;
}

.label {
    color: mix(black, white, 40%);
    font-size: 90%;
    margin-bottom: 0.3em;
}

.code {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}

.nameSection,
.codeSection {
    flex: 1;
    overflow: hidden;
}
</style>
