<template>
  <div class="SessionView">
    <div
      class="header"
      :style="style">
      <div class="top">
        <div
          class="--global-clickable"
          @click="back()">
          <svgicon
            class="backIcon"
            icon="arrow-left" />
        </div>
        <!-- <div>{{ category.name }}</div> -->
      </div>
      <!-- <div class="icon"></div> -->
      <div class="title">
        {{ $t('title') }}
      </div>
      <div class="text">
        {{ $t('introduction') }}
      </div>
      <br>
      <div class="buttons">
        <div
          class="button primary --global-clickable"
          :style="{ backgroundColor: theme.primaryColor }"
          @click="gotoNames(0)">
          <div class="buttonLabel">
            {{ $t('learn') }}
          </div>
          <!-- <div class="buttonCircle" :style="{ backgroundColor: theme.primaryColor }">
            <svgicon class="buttonIcon" icon="arrow-right"/>
          </div> -->
        </div>
        <div
          class="button --global-clickable"
          @click="start()">
          <div class="buttonLabel">
            {{ $t('start') }}
          </div>
          <!-- <div class="buttonCircle" :style="{ backgroundColor: theme.primaryColor }">
            <svgicon class="buttonIcon" icon="arrow-right"/>
          </div> -->
        </div>
      </div>
    </div>

    <div class="body">
      <div class="codesLabel">
        {{ $t('codesLabel') }}
      </div>
      <br>
      <div class="items">
        <NameWithCode
          v-for="(nameId, i) of nameIds"
          :key="nameId"
          class="--global-clickable"
          :name-id="nameId"
          @click.native="gotoNames(i)" />
      </div>
    </div>
  </div>
</template>

<translations>
  title: Memorize & Quiz
  title_no: Memorer & Quiz
  introduction: Focus on the codes in order to remember names and faces better. When you are ready you can start the quiz to practice the codes with random faces.
  introduction_no: Fokuser på kodene for å huske navn og ansikt bedre. Når du er klar så kan du starte en quiz for å praktisere kodene med tilfeldige ansikter.
  learn: Learn Codes
  learn_no: Lær Koder
  start: Start Quiz
  start_no: Start Quiz
  codesLabel: Memory codes used
  codesLabel_no: Huskekoder
</translations>

<script>
import gameHelper from '@/modules/games-shared/game-helper';
import NameWithCode from '../components/NameWithCode';

export default {
    components: { NameWithCode },
    inject: ['theme', 'module'],
    computed: {
        categoryId() {
            return this.$route.params.categoryId;
        },
        category() {
            return this.module.state.categories[this.categoryId];
        },
        nameIds() {
            return this.module.state.session.nameIds;
        },
        style() {
            return null;
        }
    },
    methods: {
        back() {
            if (this.categoryId) {
                this.$router.push({ name: 'NameSystemCategoryView', params: { categoryId: this.categoryId } });
            }
        },
        async start() {
            gameHelper.createPracticeSession({
                gameId: 'faces',
                exitUrl: this.$router.resolve({
                    name: 'NameSystemCategoryView',
                    params: { categoryId: this.categoryId }
                }).href,
                sessionOptions: {
                    numberOfItems: this.nameIds.length,
                    includeName: true,
                    items: this.module.state.session.persons.map(item => {
                        return Object.assign(item, { country: 'Western' });
                    }),
                    itemMemorizeTimeLimit: 0,
                    itemQuizTimeLimit: 0
                },
                navigate: true
            });
        },
        async gotoNames(index) {
            this.$router.push({
                name: 'NameSystemNamesView',
                params: { categoryId: this.categoryId, index }
            });
        }
    }
};
</script>

<style scoped lang="scss">
.SessionView {
}
.backIcon {
    $size: 1em;
    width: $size;
    height: $size;
    fill: white;
}
.header {
    display: flex;
    flex-direction: column;
    padding: 1em 2em;
    padding-top: 0;
}
.top {
    height: $names-headerHeight;
    display: flex;
    align-items: center;
}
.title {
    font-size: 160%;
    color: rgba(white, 0.9);
    margin-top: 3em;
}
.text {
    margin-top: 1em;
    color: rgba(white, 0.6);
    line-height: 1.5em;
}
.buttons {
    display: flex;
}
.button {
    padding: 1.5em 2em;
    flex: 1;
    border-radius: 0.5em;
    color: white;
    text-align: center;

    &:last-child {
        margin-left: 1em;
    }

    &:not(.primary) {
        background-color: transparent;
        border: 1px solid rgba(white, 0.5);
    }

    margin-top: 10em;
}
.buttonCircle {
    $size: 4em;
    width: $size;
    height: $size;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;

    margin-left: 1em;
}
.buttonIcon {
    $size: 1em;
    width: $size;
    height: $size;
    fill: white;
}
.body {
    padding: 2em;
}
.codesLabel {
    color: rgba(white, 0.9);
}
.NameWithCode {
    &:not(:first-child) {
        border-top: 1px solid rgba(black, 0.05);
    }
}

.items {
    background-color: white;
    padding: 0 1em;
    border-radius: 1em;

    flex: 1;
    overflow: hidden;
}
</style>
